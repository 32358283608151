
#customcursor {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    top: var(--y, 0);
    left: var(--x, 0);
    transform: translate(-50%, -50%);
    z-index: 20;
    mix-blend-mode: difference;
    transition: transform .2s;
}

#construction-tmp {
    color: white;
    font-size: 30px;
    letter-spacing: 15px;
    font-weight: 600;
    margin-top: 100px;
    text-align: center;
}

.main-container {
    background-color: #ffffff;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

.welcome-container {
    background-color: #88938D;
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-container {
    height: 100vh;
}

/* HOME */
.welcome-container {
    height: 100vh !important;
    margin: 0;
    padding: 0;
}

.home-element {
    position: absolute;
    top: 242px;
    left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 80px;
    width: 320px;
}

.home-element > span {
    background-color: #ffffff;
    height: 15px;
}

.home-element > span:nth-child(1) {
    width: 180px;
    margin-left: 140px;
}

.home-element > span:nth-child(2) {
    width: 320px;
}

.home-element > span:nth-child(3) {
    width: 100px;
    margin-left: 220px;
}

.home-logo-middle {
    width: 350px;
    height: 350px;
    cursor: pointer;
}


/* NAVBAR */
.dark {
    background-color: #88938D;
}

.light {
    background-color: #ffffff;
}

.home-navbar {
    display: flex;
    backdrop-filter: blur(8px);
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1030;
    width: 100vw;
    margin-left: 0 !important;
    padding: 15px 0;
    box-shadow: 0 5px 2px -2.5px #88938d4f;
    background-color: #ffffff00;
}

.nav-left, .nav-right {
    padding: 0;
    display: flex;
    align-items: center;
}

.nav-left {
    justify-content: flex-start;
    padding-left: 120px;
}

.menu-icon-div {
    display: none;
    justify-content: flex-end;
}

.nav-right {
    justify-content: flex-end;
    padding-right: 120px;
}

.nav-logo {
    padding: 0px;
    height: 80px;
}

.nav-logo-mono {
    display: none;
}

.nav-menu-icon {
    display: none;
}

.navbar-list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
    list-style-type: none !important;
}

.navbar-list-extended::marker {
    display: none;
}

.nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 80px;
    list-style-type: none !important;
}

.nav-item > ul {
    padding: 0px 10px !important;
    margin: 10px !important;
    font-size: 1em;
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
    font-weight: 300;
    letter-spacing: 3px;
}

.nav-light, .nav-dark {
    color: #ffffff;
}

.nav-dark-bg {
    background-color: #ffffff;
}

.nav-light-bg {
    background-color: #707070;
}

.nav-active {
    border: 5px solid #88938D;
}

.nav-active > ul {
    color: #88938D
}

/* NAVBAR */

.values-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    background-color: #ffffff;
    padding-bottom: 120px;
}

.values-picture-banner {
    margin: 0;
    padding: 0;
    width: 100vw;
    object-fit: cover;
}

.values-title-div  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.values-title-div > h3 {
    color: #000000;
    text-align: center;
}

.values-content {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    /* margin-bottom: 0px; */
}

.values-text-div {
    width: 443px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.values-text-div > p {
    color: #000000;
}

.values-picture {
    width: 443px;
}

.values-divider {
    margin: 0 150px 0 150px;
    height: 920px;
    width: 2px;
    background-color: #88938d4f;
}

.values-left > p {
    text-align: right;
    margin: 0 0 60px 0;
}

.values-right > p {
    text-align: left;
    margin: 15px 0 0 0;
}

#values-bottom-banner {
    margin-bottom: 50px;
}
/* VALUES */

.brands-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #88938D;
}

.brands-title-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 220px;
}

.brands-title-div > h3 {
    color: #ffffff;
    margin-bottom: 0;
}

.brands-nav-list {
    width: 1210px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #ffffff;
}

.brands-nav-items {
    color: #88938D;
    border-left: 2px solid  #88938D;
    width: 200px;
    height: 25px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 6px;
    text-align: center !important;
    margin: 0;
    padding: 4px 0 0 0 !important;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

#brands-nav-item-1 {
    border-left: 0px !important;
}

#brands-nav-item-6 {
    border-right: 0px !important;
}

.brands-nav-active {
    padding: 7px 0 0 0 !important;
    height: 35px;
    background-color: #88938D;
    color: #ffffff;
    border-radius: 20px;
    border: none;
}

ul.brands-nav-active + ul {
    border-left: none;
}

.brands-logo-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.brands-logo-div {
    display: flex;
    justify-content: space-evenly;
    margin-top: 60px;
    margin-bottom: 60px;
    align-items: center;
    width: 1210px;
}

.brands-logo-div > img {
    height: 150px;
    width: 150px;
}

/* VINS */
#brands-logo-chaimars {
    width: 250px;
}

/* ART */
#brands-logo-ghost {
    width: 300px;
}

/* COSMETIQUE */
#brands-logo-cadentia {
    width: 250px;
}

/* AUTRES */
#brands-logo-4teens {
    width: 200px;
}

#brands-logo-lxh {
    width: 200px;
    position: relative;
    top: -20px;
}

.brands-display-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0 40px 0 !important;
    background-color: #ffffff !important;
    border: none;
    width: 210px;
    height: 55px;
    box-shadow: 0 6px 3px -3px gray;
}

.brands-display-btn > p {
    color: #88938D !important;
}
/* BRANDS */


.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.contact-title-div {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 30px;
}

.contact-title-div > h3 {
    text-align: center;
    color: #000000;
}

.contact-form-div {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    margin: 0 0 50px 0;
}

.form-image {
    width: 500px;
    height: 500px;
    object-fit: cover;
}

.form-image-mobile {
    display: none;
}

.contact-right {
    display: flex;
    background-color: #88938D;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 700px;
    height: 500px;
    margin: 40px 0 20px 0;
    padding-left: 100px;
}

.contact-right > p {
    width: 300px;
    color: #ffffff;
    font-weight: 900;
    font-size: 24px;
    text-align: left;
    margin: 30px 0 30px 0;
}

.contact-form-text {
    width: 500px;
    height: 45px;
}

.contact-form-btn {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 30px;
}

.contact-form-btn-resp {
    display: none;
}

.contact-form-paragraph {
    width: 500px;
    height: 170px;
}

/* CONTACT */

/* DISCOVER PAGES */
.full-page-div {
    height: 91vh;
}

.full-page-div > h3 {
    color: #88938D;
}

.discover-container {
    background-color: #ffffff;
    overflow-x: hidden;
}

.discover-content {
    margin-top: 80px;
    padding-left: 0 !important;
}

.discover-title-div  {
    height: 200px;
}

.discover-title-div > h3 {
    color: #000000;
}

.discover-breakup {
    margin-top: 135px;
}

.discover-info-div {
    height: 388px;
    margin-bottom: 50px;
}

.discover-center-div {
    height: 477px !important;
    margin-bottom: 50px;
}

.discover-center {
    width: 754px !important;
}

.discover-left, .discover-right {
    width: 352px !important;
}

.discover-left {
    margin-right: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.discover-right {
    margin-left: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.discover-right > p, .discover-right > h4 {
    text-align: start;
}

.discover-left > p, .discover-left > h4 {
    text-align: end;
}

.discover-text-div {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.discover-text-div > h4 {
    color: #000000;

}

.discover-text-div.widder > h4 {
    width: 60%;
}

.discover-text-div > p {
    margin: 0;
    /* color: #ffffff; */
    color: #000000;
    font-size: 20px;
    width: 95%;
    font-weight: 200;
}

.discover-page-btn {
    width: 55%;
}

.discover-page-btn {
    background-color: #88938D !important;
}

.discover-page-btn > p {
    color: #ffffff;
}

.caption-right, .caption-left {
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
}

.caption-right {
    justify-content: flex-end;
}

.caption-left {
    justify-content: flex-start;
}

.caption-right > p{
    font-weight: 600;
    text-align: left;
}

.caption-left > p {
    font-weight: 600;
    text-align: right;
}

.caption-right > p ~ p, .caption-left > p ~ p{
    font-weight: 300;
}

.discover-fullpage-title {
    margin-bottom: 80px;
}

.discover-others-text {
    width: 65%;
    text-align: center;
}

.discover-fullpage-logos-div {
    display: flex;
    justify-content: center;
    width: 1350px;
}

.discover-fullpage-logo {
    margin-right: 40px;
    margin-left: 40px;
    width: 200px;
    height: 200px;
}

#discover-others-right-logo {
    margin-top: 20px;
    width: 170px;
    height: 170px;
}

#discover-others-middle-logo {
    margin-top: 30px;
}

#discover-others-left-logo {
    margin-top: 45px;
    width: 150px;
    height: 150px;
}
/* DISCOVER PAGES */


/* FOOTER */
.footer-container-dark {
    background-color: #88938D;
}

.footer-dark {
    background-color: #88938D;
}

.footer-text-dark {
    color: #ffffff;
}

.footer-container-light {
    background-color: #ffffff;
}

.footer-light {
    background-color: #ffffff;
}

.footer-text-light {
    color: #88938D;
}

/* -- RESPONSIVE -- */
@media (max-width: 480px) {
    #construction-tmp {
        font-size: 20px;
        letter-spacing: 10px;
    }

    .welcome-container {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .home-logo-middle  {
        width: 250px;
        height: 250px;
    }

    /* HOME RESP */
    .home-navbar {
        background-color: rgba(255, 255, 255, 0);
        height: 60px;
        box-shadow: 0 5px 2px -2.5px #88938d4f;
    }

    .nav-extended {
        height: 235px;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
    }

    .nav-left {
        justify-content: space-between;
        width: 100vw;
    }

    .nav-right {
        display: none;
    }

    .nav-left-extended {
        box-shadow: 0 5px 2px -2.5px #88938d4f;
    }
    
    .nav-right-extended {
        width: 100vw;
        display: flex;
    }

    .nav-logo {
        height: 50px;
        padding: 0px 10px;
        display: none;
    }

    .nav-logo-extended {
        margin-top: 5px;
    }

    .nav-logo-mono {
        display: block;
    }

    .nav-menu-icon {
        height: 30px;
        display: block;
        margin-left: 15px;
    }

    .menu-icon-extended {
        margin-top: 5px;
    }

    .navbar-list {
        flex-direction: column;
    }

    .nav-item {
        height: 60px;
    }

    .nav-item > ul {
        color: #000000;
        font-weight: 900;
        font-size: 18px;
    }

    .nav-divider {
        display: none;
    }
    /* NAVBAR RESP */

    .values-container {
        margin-top: 0px;
    }

    .values-title-div  {
        height: 130px;
        width: 340px;
        padding-top: 20px;
    }
/* 
    .values-title-div > h3 {
        font-size: 30px;
    } */

    .values-picture-banner {
        height: 50vh;
        object-position: 80% 0;
    }

    #values-bottom-banner {
        object-position: 50% 0;
    }

    .values-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .values-text-div {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .values-text-div > p {
        width: 340px;
        text-align: left;
        margin: 25px 0 25px 0;
        font-size: 16px
    }

    .values-left > img {
        display: none;
    }

    .values-picture {
        width: 340px
    }

    .values-divider {
        display: none;
    }
    /* VALUES RESP */

    .brands-title-div {
        margin-top: 0px;
        height: 130px;
    }

    .brands-nav-list {
        display: none;
    }
    
    /* BRANDS RESP */

    .contact-form-div {
        flex-direction: column;
    }

    .form-image {
        display: none;
    }

    .form-image-mobile {
        display: block;
        width: 100%;
    }

    .contact-right {
        width: 100%;
        margin: 0;
        padding: 0 20px 0 20px;
    }

    .contact-form-text {
        height: 40px;
        width: 100%;
    }

    .contact-form-paragraph {
        height: 120px;
        width: 100%;
    }
    /* CONTACT RESP */
}

@media (max-width: 380px) {
    
    .values-title-div  {
        width: 280px;
    }

    .values-text-div > p {
        width: 260px;
        font-size: 16px;
    }

    .values-picture {
        width: 260px
    }
}
