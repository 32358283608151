@font-face {

    font-family: 'amalfi';
    src: url('../fonts/amalfi_coast-webfont.woff2') format('woff2'),
         url('../fonts/amalfi_coast-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Krona+One&family=Raleway:ital,wght@0,300;0,400;0,600;0,900;1,400&display=swap');

/* body {
    cursor: none;
} */

html, body {
    scroll-behavior: smooth !important;
}

a, a:hover {
    text-decoration: none !important;
}

h3, p {
    letter-spacing: 3px;
}

h3 {
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 8px !important;
}

h4 {
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    font-size: 1.5em;
    font-weight: 600;
}

p {
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 300;
}

button {
    border: none !important;
    border-radius: 0px !important;
    background-color: #ffffff !important;
}

button > p {
    color: #707070;
    font-size: 1em;
    font-weight: 600;
}

input, textarea {
    border: none !important;
    outline: none !important;
    padding: 0 15px 0 15px;
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 10px;
    border-radius: 0px;
}

textarea {
    padding: 15px;

}

ul {
    padding: 0 !important;
}

ol, ul {  
    list-style-type: none;  
}

svg {
    color : black;
}

@media (max-width: 480px) {
    h3 {
        font-size: 24px !important;
    }
}